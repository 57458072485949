<template>
  <div class="nl-field nl-dd" :class="{'nl-field-open': isOpen}">
	<a class="nl-field-toggle" @click.stop="$emit('clickOnWord', $)">{{ choosen }}</a>
	<ul>
        <li v-for="(el, idx) in options"
            :key="idx"
            :class="{'nl-dd-checked': options[choosenId] === el }"
            @click.stop="choose(el, idx, $)">
            {{ el }}
        </li>
	</ul>
</div>
</template>

<script>
export default {
    name: 'NLSelect',
    data() {
        return {
            isOpen: false,
            firstPlaceholder: '',
            choosen: '',
            choosenId: null,
        }
    },
    props: {
        options: {
            type: Array,
            required: true,
        }
    },
    methods: {
        changeVisible() {
            this.isOpen = !this.isOpen;
        },
        choose(el, id, $) {
            this.$emit('clickOnWord', $);
            this.choosen = el;
            this.choosenId = id;
        }
    },
    mounted() {
        this.choosenId = 0;
        this.choosen = this.options[this.choosenId];
    },
    emits: ['clickOnWord'],

}
</script>

<style>
    .nl-field ul {
        position: absolute;
        visibility: hidden;
        background: #ffe86d;
        border-radius: 1.5rem;
        left: -0.5em;
        top: 50%;
        font-size: 80%;
        opacity: 0;
        transform: translateY(-40%) scale(0.9);
        transition: visibility 0s 0.3s, opacity 0.3s, transform 0.3s;
    }

    .nl-field.nl-field-open ul {
        visibility: visible;
        opacity: 1;
        transform: translateY(-50%) scale(1);
        transition: visibility 0s 0s, opacity 0.3s, transform 0.3s;
    }
    .nl-field ul li {
	color: #af8961;
	position: relative;
    }

    .nl-dd ul li {
        padding: 0 1.5em 0 0.5em;
        cursor: pointer;
        white-space: nowrap;
    }

    .nl-dd ul li.nl-dd-checked {
        color: #478982;
    }

    .no-touch .nl-dd ul li:hover {
        background: rgba(0,0,0,0.05);
    }

    .no-touch .nl-dd ul li:hover:active {
        color: #478982;
    }
</style>