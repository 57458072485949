<template>
  <div class="nl-field nl-ti-text" :class="{'nl-field-open': isOpen}">
	<a class="nl-field-toggle" @click.stop="toggle($)">{{ field || placeholder }}</a>
	<ul>
		<li class="nl-ti-input">
			<input 
                id="inpt"
                type="text"
                v-model="value"
                :placeholder="placeholder"
                @keydown.enter.passive="toggle($)"
            />
		</li>
	</ul>
</div>
</template>

<script>
export default {
    name: 'NLInput',
    data() {
        return {
            isOpen: false,
            field: '',
            value: '',
        }
    },
    props: {
        placeholder: {
            type: String,
            required: true,
        },
    },
    watch: {
        'isOpen': function(n, o) {
            if (n) {
                // focus on input field
                const inpt = document.getElementById('inpt');

                setTimeout(() => {
                    inpt.focus();
                }, 0)
            }
            if (o) {
                this.field = this.value;
            }
        },
    },
    emits: ['clickOnWord'],
    methods: {
        toggle($) {
            this.$emit('clickOnWord', $);
        },
        changeVisible() {
            this.isOpen = !this.isOpen;
        }
    }, 
}
</script>

<style>
.nl-ti-text ul li.nl-ti-input input {
	width: 85%;
	padding: 0.2em 0.2em 0.2em 0.5em;
	border-bottom: none;
	color: rgb(104, 104, 104);
}

</style>