<template>
<div class="main">
  <div class='fx-cntr'><img alt="doge" src="./assets/doge.png"></div>
  <div id="nl-story" class="nl-story">
    When I was four years old I had a dog named Benny. He was a faithful dog to our family.
        <br>
        One day when I was in the house playing with my dolls, Benny was not with me. I knew that the back door was open and I could get outdoors without being seen. I got outdoors and had started down the lane to the canal when I felt something pulling on my dress. I looked around and there was Benny. Oh, how angry I was. I slapped Benny and he ran away.
        <br>
        ... My parents were looking for Benny for two days. But they could not find it.
        <br>
        I'm an adult now, but I still regret what I did with Benny.
        I once met a canine psychologist and we found a way to quickly find missing dogs. To do this, we unite people who are not indifferent to your grief near you.
        <br>
  </div>
  <div id="nl-form" class="nl-form">
        Tell about yourself and your dog so that people in your neighborhood can join the search for your dog:
        <br>
        My name is
        <NLInput
          @clickOnWord="changeNLVisibility"
          :placeholder="'Andrey'"/><br /> and I have a
        <NLSelect 
          :options="['boy', 'girl', 'g-b']"
          @clickOnWord="changeNLVisibility"/>
        dog
        <br />named
        <NLSelect 
          :options="['Vadim', 'Andrey', 'Peddy', 'Huedy']"
          @clickOnWord="changeNLVisibility"/>
    <Overlay v-if="overlayVisible" @clickOnShadow="clickOnShadow"/>
  </div>
</div>
</template>

<script>
import 'normalize.css'
import NLSelect from './components/NLSelect.vue'
import NLInput from './components/NLInput.vue'
import Overlay from './components/Overlay.vue'

export default {
  name: 'App',
  data() {
    return {
      NLElemIsShown: false,
      closeNLElem: null,
      overlayVisible: false,
    }
  },
  components: {
    NLSelect,
    NLInput,
    Overlay,
  },
  watch: {
    "NLElemIsShown": function() {
      this.overlayVisible = !this.overlayVisible;
    }
  },
  methods: {
    changeNLVisibility(s) {
      s.ctx.changeVisible();
      this.NLElemIsShown = !this.NLElemIsShown;
      this.closeNLElem = s.ctx.changeVisible;
    },
    clickOnShadow() {
      this.closeNLElem();
      this.NLElemIsShown = !this.NLElemIsShown;
    },
  },
}
</script>

<style>
html {
  padding-right: 17px;
}
body {
  color: #ffe8e7;
  background: #E96D65;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.main {
  max-width: 50em;
  margin: 0 auto;
  padding: 2em;
}
/* general style for the form */
.nl-story {
	width: 100%;
	margin: 0.3em auto 0 auto;
	font-size: 4em;
	line-height: 1.5;
}
.nl-form {
	width: 100%;
	margin: 0.3em auto 0 auto;
	font-size: 6em;
	line-height: 1.5;
}
/* Let’s remove the default styling for all the lists */
.nl-story ul,
.nl-form ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
/* And we’ll also normalize the form elements and make them look like everything else */
.nl-story input,
.nl-story select,
.nl-story button,
.nl-form input,
.nl-form select,
.nl-form button {
	border: none;
	background: transparent;
	font-family: inherit;
	font-size: inherit;
	color: inherit;
	font-weight: inherit;
	line-height: inherit;
	display: inline-block;
	padding: 0;
	margin: 0;
}
.nl-story input:focus,
.nl-form input:focus {
	outline: none;
}
.nl-field {
	display: inline-block;
	position: relative;
}
.nl-field.nl-field-open {
	z-index: 10000;
}
.nl-field-toggle,
.nl-story select,
.nl-form select  {
	line-height: inherit;
	display: inline-block;
	color: #b14943;
	cursor: pointer;
	border-bottom: 1px solid #b14943;
}
.nl-story input,
.nl-form input {
  line-height: inherit;
	display: inline-block;
	color: #b14943;
	cursor: pointer;
}

.nl-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.5);
	opacity: 0;
	z-index: 9999;
	visibility: hidden;
	transition: visibility 0s 0.3s, opacity 0.3s;
}

.nl-field.nl-field-open ~ .nl-overlay {
	opacity: 1;
	visibility: visible;
	-webkit-transition-delay: 0s;
	-moz-transition-delay: 0s;
	transition-delay: 0s;
}
/* .fx-cntr {
  display: flex;
  justify-content: center;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  opacity: 0;
  z-index: 9999;
  visibility: hidden;
  transition: visibility 0s 0.3s, opacity 0.3s;
}
.overlay-active {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
} */
@media screen and (max-width: 45em) {
	.nl-story {
		font-size: 2em;
	}
  .nl-form {
		font-size: 3em;
	}
}

@media screen and (max-width: 25em) {
	.nl-story {
		font-size: 1em;
	}
  .nl-form {
		font-size: 2em;
	}
}
</style>
