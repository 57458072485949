<template>
  <div v-if="isVisible" class="nl-overlay" @click.stop="$emit('clickOnShadow', $)"></div>
</template>

<script>
export default {
    name: 'Overlay',
    data() {
        return {
            isVisible: true,
        }
    },
    emits: ['clickOnShadow'],
    methods: {
        changeVisibile() {
            this.isVisible = !this.isVisible
        },
    },
}
</script>

<style>

</style>